
import { computed, defineComponent, reactive, toRefs } from 'vue'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { useMutation } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import storage, { StorageType } from '@/utils/storage'
import dayjs from 'dayjs'
import paramsWrap from '@/apollo-client/paramsWrap'
export default defineComponent({
  props: {
    dialogVisible: { type: Boolean, default: false },
    title: { type: String, default: '' },
    phoneMessage: { type: Object, default: () => ({}) }
  },
  emits: ['update:dialogVisible'],
  setup(props, { emit }) {
    const router = useRouter()
    const state = reactive({
      phone: '',
      areaCode: '',
      code: '',
      countFlag: false,
      loading: false,
      countNum: 60,
      username: '',
      password: '',
      codeStr: ''
    })
    const open = () => {
      state.phone = props.phoneMessage.phone
      state.areaCode = props.phoneMessage.area_code
      state.username = props.phoneMessage.username
      state.password = props.phoneMessage.password
    }
    const dialogVisibleFlag = computed({
      get() {
        return props.dialogVisible
      },
      set(val) {
        emit('update:dialogVisible', val)
      }
    })
    const handleClose = () => {
      emit('update:dialogVisible', false)
    }
    const close = () => {
      emit('update:dialogVisible', false)
      state.code = ''
    }
    // 登录
    const { mutate: loginDirectFunc } = useMutation(gql.lpoaLoginManager, {
      clientId: 'dmz'
    })
    // 获取用户信息
    // const { result: postInfo } = useQuery(gql.lpoaManagersBindInfoList)
    const confirm = () => {
      if (state.code === '') {
        ElMessage({
          message: '请输入验证码',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (state.code.length < 6) {
        ElMessage({
          message: '请输入正确的验证码',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      state.loading = true
      const obj = {
        manager: state.username,
        password: paramsWrap(state.password),
        loginIp: '127.0.0.1',
        codeType: 'check_lpoa_login_sms',
        code: state.code
      }
      loginDirectFunc({ params: obj }).then((result: any) => {
        const { lpoaLoginManager } = result.data
        console.log(lpoaLoginManager)
        if (lpoaLoginManager.ok) {
          storage.rcSetItem(
            StorageType.local,
            'sessionId',
            lpoaLoginManager.data.sessionId
          )
          // 获取用户信息 ( 暂时取消 )
          // const list = useResult(postInfo, [], data => data)
          // console.log(list, 'listlistlistlistlist')
          storage.rcSetItem(
            StorageType.local,
            'userInfo',
            lpoaLoginManager.data.manager
          )
          state.loading = false
          router.push({
            path: '/lpoa/realTimeData'
          })
          storage.rcSetItem(StorageType.local, 'expireTime', dayjs())
          ElMessage({
            message: '登录成功',
            type: 'success',
            customClass: 'custom-success'
          })
        } else {
          state.loading = false
          ElMessage({
            message: lpoaLoginManager?.reason.desc,
            type: 'error',
            customClass: 'custom-error'
          })
        }
      })
    }
    const { mutate: loginDirectFuncSend } = useMutation(gql.lpoaLoginManager, {
      clientId: 'dmz'
    })
    // 倒计时
    const countDown = () => {
      const captcha = new window.TencentCaptcha(
        process.env.VUE_APP_H5_TENCENT,
        function (res: any) {
          if (res.ret === 0) {
            console.log(res)
            // 页面上滑动正确，请求自己的业务接口
            // 记得把验证成功的票据和随机字符带到自己接口中去腾讯验证票据的真实性
            state.codeStr = res.ticket + ',' + res.randstr
            const obj = {
              code: state.codeStr,
              manager: state.username,
              password: paramsWrap(state.password),
              loginIp: '127.0.0.1',
              codeType: 'send_lpoa_login_sms'
            }
            console.log(obj)
            loginDirectFuncSend({ params: obj }).then((result: any) => {
              const { lpoaLoginManager } = result.data
              console.log(lpoaLoginManager)
              if (lpoaLoginManager.reason.err === 35114) {
                ElMessage({
                  message: '短信验证码发送成功',
                  type: 'success',
                  customClass: 'custom-success'
                })
                return
              }
              ElMessage({
                message: lpoaLoginManager.reason.desc,
                type: 'error',
                customClass: 'custom-error'
              })
            })
            // 更改btn状态
            state.countFlag = !state.countFlag
            // 设置倒计时
            // let timer: any
            const timer = setInterval(() => {
              if (state.countNum <= 0) {
                // 清除定时器
                clearInterval(timer)
                // 更改btn状态
                state.countFlag = !state.countFlag
                // 重置倒计时状态
                state.countNum = 60
              }
              state.countNum--
            }, 1000)
          }
        }
      )
      captcha.langFun()
      // 滑块显示
      captcha.show()
    }
    return {
      handleClose,
      confirm,
      close,
      dialogVisibleFlag,
      countDown,
      open,
      ...toRefs(state)
    }
  }
})
