
import { defineComponent, reactive, watch, ref, toRefs } from 'vue'
import { useRoute, LocationQuery } from 'vue-router'
import messagePop from './components/messagePop.vue'
import { nanoid } from 'nanoid'
import storage, { StorageType } from '@/utils/storage'
// indexedb
import { judgeVal } from '@/utils/indexedb'
import resA from '../../../../public/localClock/testA.json'
import resHK from '../../../../public/localClock/testHK.json'
import resUS from '../../../../public/localClock/testUS.json'
import ChangePwd from './components/changePwd.vue'
import { isExpire, useLogin, useRegisterDevice } from './hooks'
import { User, Lock } from '@element-plus/icons-vue'
import paramsWrap from '@/apollo-client/paramsWrap'

export default defineComponent({
  components: {
    messagePop,
    ChangePwd
  },
  setup() {
    const route = useRoute()
    const { registerDevice } = useRegisterDevice()
    const deviceId = storage.rcGetItem(StorageType.local, 'deviceId')
    if (!deviceId) {
      const obj = {
        hwId: nanoid(),
        installId: nanoid(),
        versionName: 'web',
        versionCode: 1,
        platform: 'android'
      }
      registerDevice({ params: obj })
    }
    const loginFormRef = ref()
    const state = reactive({
      revisePwdDialog: false,
      loginForm: {
        username: '',
        password: ''
      },
      loading: false,
      capsTooltip: false,
      redirect: '',
      otherQuery: {},
      bg: {
        backgroundImage: 'url(' + require('@/assets/images/login/bg.png') + ')',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
      },
      logo: require('@/assets/images/login/logo.svg'),
      // indexedb 版本信息
      usv: resUS.result.lastVersion,
      hkv: resHK.result.lastVersion,
      av: resA.result.lastVersion,
      versionDb: '', // 存储库版本号
      pwdDialog: false // 重置密码
    })
    const rules = reactive({
      username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
      password: [
        { required: true, message: '请输入密码', trigger: 'blur' },
        { pattern: /^[A-Za-z0-9.]+$/, message: '密码格式不正确' }
      ]
    })
    const routerQuery = ref(route.query)
    // 登录 账户密码
    const { loginDirect, promptDialog, confirmPopupFlag, phoneMessage } =
      useLogin(state.loginForm)
    const handleLogin = async (loginFormRef: any) => {
      isExpire()
      // 登录的方法
      await loginFormRef.validate((valid: boolean) => {
        state.loading = true
        if (valid) {
          // 发送请求
          const obj = {
            manager: state.loginForm.username,
            password: paramsWrap(state.loginForm.password),
            // password: state.loginForm.password,
            loginIp: '127.0.0.1',
            codeType: 'get_lpoa_phone_mask'
          }
          loginDirect({ params: obj }).then(() => {
            state.loading = false
          })
        } else {
          console.log('错误验证')
        }
      })
    }
    const checkCapslock = (e: KeyboardEvent) => {
      const { key } = e
      state.capsTooltip =
        key !== null && key?.length === 1 && key >= 'A' && key <= 'Z'
    }

    function getOtherQuery(query: LocationQuery) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {} as LocationQuery)
    }

    watch(
      () => routerQuery,
      (query: any) => {
        if (query) {
          console.log(query)
          state.redirect = query.redirect?.toString() ?? ''
          state.otherQuery = getOtherQuery(query)
        }
      }
    )
    // 本地版本 =>
    const versionKo = () => {
      const verNum = Number(state.usv) + Number(state.hkv) + Number(state.av)
      console.log('本地版本号', verNum)
      // 获取pubic码表版本号
      if (!storage.rcGetItem(StorageType.local, 'bpVersionN')) {
        storage.rcSetItem(StorageType.local, 'bpVersionN', verNum)
      } else {
        const bpVersionN = storage.rcGetItem(StorageType.local, 'bpVersionN')
        console.log('内存版本号', bpVersionN)
        if (Number(bpVersionN) !== verNum) {
          // 更新使用本地版本号更新
          // 新版本 打包进入的 version 肯定和现有本地的不相符 作对比更新 把新的本地码表读到本地
          judgeVal(
            'web_trade',
            state.usv + ',' + state.hkv + ',' + state.av,
            state.usv + state.av + state.hkv,
            resUS,
            resHK,
            resA,
            'change'
          )
          storage.rcSetItem(StorageType.local, 'bpVersionN', verNum)
        }
      }
    }
    versionKo()
    // 判断是否有 indexedb 存储库 => 第二个参数顺序为美港A
    // 存储本地码表 -> 判断本地有没有存储 有无需操作 无存储
    judgeVal(
      'web_trade',
      state.usv + ',' + state.hkv + ',' + state.av,
      state.usv + state.av + state.hkv,
      resUS,
      resHK,
      resA,
      false
    )
    // 重置密码
    const showPwdDialog = () => {
      state.pwdDialog = true
    }
    const toRevise = () => {
      state.revisePwdDialog = true
    }

    return {
      loginFormRef,
      ...toRefs(state),
      checkCapslock,
      rules,
      showPwdDialog,
      toRevise,
      handleLogin,
      promptDialog,
      confirmPopupFlag,
      phoneMessage,
      User,
      Lock
    }
  }
})
