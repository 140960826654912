
import { defineComponent, reactive, toRefs } from 'vue'
import { useMutation, useQuery } from '@vue/apollo-composable'
import * as gql from '@/graphql'
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
    dialogVisible: { type: Boolean, default: false }
  },
  emits: ['update:dialogVisible', 'confirm'],
  setup(props, { emit }) {
    const state = reactive({
      idCard: '',
      phone: '',
      smsCode: '',
      areaCode: '86',
      countFlag: false,
      loading: false,
      countNum: 60,
      areaCodeList: [{ areaCode: '86', cnName: '中国' }],
      codeStr: ''
    })
    // 区号
    const { onResult } = useQuery(gql.lpoaAreaCodes, null, {
      clientId: 'dmz'
    })
    onResult(res => {
      state.areaCodeList = res.data.lpoaAreaCodes.data.options
    })
    const { mutate: resetPwd } = useMutation(gql.lpoaResetLpoaPwd, {
      clientId: 'dmz'
    })
    const close = () => {
      state.idCard = ''
      state.phone = ''
      state.smsCode = ''
      emit('update:dialogVisible', false)
    }
    const confirm = () => {
      if (!state.idCard) {
        ElMessage({
          message: '证件号码不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (!state.phone) {
        ElMessage({
          message: '手机号不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      if (!state.smsCode) {
        ElMessage({
          message: '验证码不能为空',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      state.loading = true
      resetPwd({
        params: {
          code: state.codeStr,
          idCard: state.idCard,
          areaCode: state.areaCode,
          phone: state.phone,
          smsCode: state.smsCode,
          codeType: 'reset_pwd'
        }
      }).then(res => {
        const { lpoaResetLpoaPwd } = res?.data
        if (lpoaResetLpoaPwd.ok) {
          ElMessage({
            message: '确认重置成功',
            type: 'success',
            customClass: 'custom-success'
          })
          state.idCard = ''
          state.phone = ''
          state.smsCode = ''
          state.loading = false
          emit('update:dialogVisible', false)
          return
        }
        ElMessage({
          message: lpoaResetLpoaPwd.reason.desc,
          type: 'error',
          customClass: 'custom-error'
        })
        state.loading = false
      })
    }
    const selectAreaCode = (val: string) => {
      state.areaCode = val
    }
    const countDown = () => {
      if (!state.phone) {
        ElMessage({
          message: '请输入手机号后获取验证码',
          type: 'warning',
          customClass: 'custom-warning'
        })
        return
      }
      const captcha = new window.TencentCaptcha(
        process.env.VUE_APP_H5_TENCENT,
        function (res: any) {
          if (res.ret === 0) {
            console.log(res)
            // 页面上滑动正确，请求自己的业务接口
            // 记得把验证成功的票据和随机字符带到自己接口中去腾讯验证票据的真实性
            state.codeStr = res.ticket + ',' + res.randstr
            const obj = {
              code: state.codeStr,
              idCard: state.idCard,
              areaCode: state.areaCode,
              phone: state.phone,
              codeType: 'send_sms'
            }
            resetPwd({ params: obj }).then((result: any) => {
              const { lpoaResetLpoaPwd } = result.data
              if (lpoaResetLpoaPwd.ok) {
                ElMessage({
                  message: '短信验证码发送成功',
                  type: 'success',
                  customClass: 'custom-success'
                })
                return
              }
              ElMessage({
                message: lpoaResetLpoaPwd.reason.desc,
                type: 'error',
                customClass: 'custom-error'
              })
            })
            // 更改btn状态
            state.countFlag = !state.countFlag
            const timer = setInterval(() => {
              if (state.countNum <= 0) {
                clearInterval(timer)
                state.countFlag = !state.countFlag
                state.countNum = 60
              }
              state.countNum--
            }, 1000)
          }
        }
      )
      captcha.langFun()
      // 滑块显示
      captcha.show()
    }
    return {
      ...toRefs(state),
      close,
      confirm,
      countDown,
      selectAreaCode
    }
  }
})
