/** IndexedDB 使用示例 (以下为个人封装版, 具体内容下方有注释)
     // @params: 第一个参数: db名, 第二个参数: 表名, 第三个参数(注意了): 类似于数据库的主键key
     initDB('db', 'mytable', 'url') // 初始化
     // @params: 第三个参数要存的值
     setVal('db', 'mytable', {url: 222, data: {a: 11111}}) // 存数据
     // @params: 第三个参数是主键key对的值, 根据这个值, 拿到之前存过的值
     getVal('db', 'mytable', 222, (e) => { console.log(e) }) // 取数据  返回: {url: 222, data: {…}}
     注：初始化成功后，控制台的Application内能看到。 新db注册后, 要关闭控制台, 重开一遍, 才能在控制台的Application内看见
 */
import storage, { StorageType } from '@/utils/storage'
/*
 *@Description: 去重
 *@param: {*}
 *@return: {*}
 */
function arrayNonRepeatfy (arr: any) {
  const map = new Map()
  const arrayL = []
  for (let i = 0; i < arr.length; i++) {
    if (map.has(arr[i].id)) {
      map.set(arr[i].id, true)
    } else {
      map.set(arr[i].id, false)
      arrayL.push(arr[i])
    }
  }
  return arrayL
}
function arrayNonRepeatfyID (arr: any) {
  const map = new Map()
  const arrayL = [] as any
  for (let i = 0; i < arr.length; i++) {
    if (map.has(arr[i].id)) {
      map.delete(arr[i].id)
    } else {
      map.set(arr[i].id, arr[i])
    }
  }
  map.forEach((v: any) => {
    arrayL.push(v)
  })
  return arrayL
}

export const initDB = function (
  dbName: any,
  tableName: any,
  key: any,
  version: any
) {
  // 初始化db
  const IndexedDB = window.indexedDB.open(dbName, version)
  IndexedDB.onupgradeneeded = function (event: any) {
    // 数据库版本 version
    // 如果指定的版本号，大于数据库的实际版本号，就会发生数据库升级事件upgradeneeded
    // 新建数据库
    const result = event.target.result
    if (!result.objectStoreNames.contains(tableName)) {
      // 先判断库是否存在
      result
        .createObjectStore(tableName, { keyPath: key }) // 表的主键 是 url 比如{url: 'xx', data: {xx}}  主键会自动生成索引
        .createIndex(key, key, { unique: true }) // 建立索引
    }
    IndexedDB.result.close()
  }
}

export const setVal = function (dbName: any, tableName: any, setData: any) {
  try {
    const IndexedDB1 = window.indexedDB.open(dbName)
    IndexedDB1.onsuccess = function () {
      const db = IndexedDB1.result
      db.transaction([tableName], 'readwrite') // 指定需要访问的 数据库名
        .objectStore(tableName) // 返回数据库对象
        .put(setData)
      db.close()
    }
  } catch (error) {
    console.error(error)
  }
}
/*
 *@Description: 处理数据
 *@param: {*}
 *@return: {*}
 */
// const pullDateList = (added: any, expired: any, update: any, arr: any) => {
//   let arrNew = []
//   const filterArr = arrayNonRepeatfyID(arr.concat(expired).concat(update))
//   arrNew = filterArr.concat(added).concat(update)
//   // 插入 按照顺序插入 => 根据 mkt
//   return arrNew
// }
const pullDateList = (
  addedUS: any,
  expiredUS: any,
  updateUS: any,
  addedHK: any,
  expiredHK: any,
  updateHK: any,
  addedA: any,
  expiredA: any,
  updateA: any,
  arr: any
) => {
  let arrNew = []
  // hk
  const fltHk = arr.filter((v: any) => v.mkt === 'HK')
  const fltUs = arr.filter((v: any) => v.mkt === 'US')
  const fltA = arr.filter((v: any) => v.mkt === 'SZ' || v.mkt === 'SH')
  // 插入 按照顺序插入 => 根据 mkt
  const filterArrHk = arrayNonRepeatfyID(
    fltHk.concat(expiredHK).concat(updateHK)
  )
  const filterArrUs = arrayNonRepeatfyID(
    fltUs.concat(expiredUS).concat(updateUS)
  )
  const filterArrA = arrayNonRepeatfyID(fltA.concat(expiredA).concat(updateA))
  const arrNewHk = filterArrHk.concat(updateHK).concat(addedHK)
  const arrNewUs = filterArrUs.concat(updateUS).concat(addedUS)
  const arrNewA = filterArrA.concat(updateA).concat(addedA)
  arrNew = arrNewUs.concat(arrNewHk).concat(arrNewA)
  return arrNew
}

/*
 *@Description: 判断数据库是否存在 // 存储各市场版本号
 *@param: { update: 有存储库的情况下是否需要更新原有存储库 }
 *@return: {*}
 */
export const judgeVal = (
  dbName: any,
  versionStr: any,
  version: any,
  resUS: any,
  resHK: any,
  resA: any,
  update: any
) => {
  try {
    const IndexedDB1 = window.indexedDB.open(dbName)
    IndexedDB1.onsuccess = function () {
      // callback(IndexedDB1)
      console.log(IndexedDB1)
      if (IndexedDB1.result.objectStoreNames.length < 1) {
        IndexedDB1.result.close()
        console.log('被删除了')
        // 初始化没有存储库
        console.time()
        initDB('web_trade', 'db01', 'key', '090')
        setVal('web_trade', 'db01', {
          key: '0201',
          data: resUS.result.added
            .concat(resHK.result.added)
            .concat(resA.result.added)
        })
        console.timeEnd('存储时间')
        storage.rcSetItem(
          StorageType.local,
          'version',
          versionStr + ',' + version + ',' + '0'
        )
      } else if (update && update !== 'change') {
        console.log('0', '更新了')
        // 获取存储库所有的数据
        console.time()
        // eslint-disable-next-line
        getVal('web_trade', 'db01', '0201', 'all-all-all', (e: any) => {
          console.log(e)
          // added新增的，expired过期的，update是更新的  => 处理数据
          // const addedList = resUS.added.concat(resHK.added).concat(resA.added)
          // const exList = resUS.expired.concat(resHK.expired).concat(resA.expired)
          // const updateList = resUS.updated.concat(resHK.updated).concat(resA.updated)
          console.time()
          // const arr = pullDateList(addedList, exList, updateList, e.data)
          const arr = pullDateList(
            resUS.added,
            resUS.expired,
            resUS.updated,
            resHK.added,
            resHK.expired,
            resHK.updated,
            resA.added,
            resA.expired,
            resA.updated,
            e.data
          )
          console.timeEnd()
          console.log(
            arr,
            '!!!!!!!!!!!!!更新码表之后的数据!!!!!!!!!!!!!!!!!!!!!'
          )
          // 有存储库
          console.log('版本号', version)
          initDB('web_trade', 'db01', 'key', '090')
          setVal('web_trade', 'db01', {
            key: '0201',
            data: arr
          })
          console.log(
            versionStr,
            'versionStrversionStrversionStrversionStrversionStr'
          )
          storage.rcSetItem(
            StorageType.local,
            'version',
            versionStr + ',' + version + ',' + '1'
          )
        })
        console.timeEnd()
      } else if (update === 'change') {
        // 有存储库需要更新 把本地打包的码表更新到 浏览器本地存储
        console.time()
        IndexedDB1.result.close()
        console.log(resUS.result.added, 'resUS.result.addedresUS.result.added')
        initDB('web_trade', 'db01', 'key', '090')
        setVal('web_trade', 'db01', {
          key: '0201',
          data: resUS.result.added
            .concat(resHK.result.added)
            .concat(resA.result.added)
        })
        console.timeEnd('存储时间')
        storage.rcSetItem(
          StorageType.local,
          'version',
          versionStr + ',' + version + ',' + '0'
        )
        console.timeEnd()
      }
    }
  } catch (error) {
    // callback()
    console.error(error)
  }
}

export const getVal = function (
  dbName: any,
  tableName: any,
  key: any,
  value: any,
  cb: any
) {
  const IndexedDB2 = window.indexedDB.open(dbName)
  IndexedDB2.onsuccess = function () {
    const db = IndexedDB2.result
    if (IndexedDB2.readyState === 'pending') {
      // 正在写入 查接口数据
      // eslint-disable-next-line
      cb('pending')
      return
    }
    if (db.objectStoreNames.length < 1) {
      // eslint-disable-next-line
      cb('error')
      db.close()
      return
    }
    if (db.objectStoreNames.contains(tableName)) {
      const request = db
        .transaction([tableName], 'readwrite')
        .objectStore(tableName)
        .get(key) // key是: JSON.stringify(opt)
      request.onsuccess = function () {
        if (request.result) {
          if (value === 'all-all-all') {
            console.log('all-all-all')
            cb(request.result)
          } else {
            // eslint-disable-next-line
            cb(fuzzySearch(value, request.result.data))
          }
        } else {
          throw Error('indexDB 获取失败, key是: ' + key)
        }
      }
    }
    db.close()
  }
  IndexedDB2.onerror = function () {
    console.log('数据库打开报错')
  }
}
/*
 *@Description: 查找股票
 *@param: { 1: 精确查找股票代码, 码表增加属性: 股票简称, 精确查找股票简称 }
 *@param: { 2: 模糊查询股票代码 }
 *@param: { 3: 精确+模糊查找首字母 }
 *@param: { 4: 模糊查找全拼, 中文则跳过前面1 2 3 }
 *@return: {*}
 */
const fuzzySearch = (value: any, data: any) => {
  console.log(value)
  let arr = []
  let arrUS = []
  const similarArr = [] as any
  // 纯数字代码
  if (/^\d{1,}$/.test(value)) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].c.includes(value) && data[i].t === 1) {
        arr.push(data[i])
      }
    }
    // 纯英文字母
  } else if (/^[a-zA-Z]+$/.test(value.toLowerCase())) {
    for (let i = 0; i < data.length; i++) {
      // 英文的股票代码
      if (
        data[i].c.toLowerCase().includes(value.toLowerCase()) &&
        data[i].t === 1
      ) {
        arrUS.push(data[i])
        if (
          data[i].c.toLowerCase() === value.toLowerCase() &&
          data[i].t === 1
        ) {
          arrUS = [data[i]]
        }
      }
      // 股票代码首字母
      if (
        data[i].kws.toLowerCase().includes(value.toLowerCase()) &&
        data[i].t === 1
      ) {
        arr.push(data[i])
        if (value.toLowerCase() === 'txkg') {
          arr = arr.reverse()
        }
      }
    }
    // 纯中文 similar
  } else if (/^[\u4e00-\u9fa5]+$/.test(value)) {
    for (let i = 0; i < data.length; i++) {
      if (data[i].sn.includes(value) && data[i].t === 1) {
        arr.push(data[i])
        // if (data[i].sn === value) {
        //   arr[0] = data[i]
        // }
      }
      // 保留相似度查验
      //   if (similar(value, data[i].sn) >= 0.5 && data[i].t === 1) {
      //     similarArr.push(data[i])
      //     if (data[i].sn === value && similar(value, data[i].sn) == 1.000) {
      //       console.log('进来了')
      //       similarArr = [data[i]]
      //     }
      //   } else if (data[i].sn.includes(value) && data[i].t === 1) {
      //     arr.push(data[i])
      //     if (data[i].sn === value) {
      //       arr[0] = data[i]
      //     }
      //   }
    }
    // 非纯中文 非纯数字 非字母
  } else {
    for (let i = 0; i < data.length; i++) {
      // 拼音简称
      if (
        data[i].kws.toLowerCase().includes(value.toLowerCase()) &&
        data[i].t === 1
      ) {
        arr.push(data[i])
      }
      // 中文名称
      if (
        data[i].sn.toLowerCase().includes(value.toLowerCase()) &&
        data[i].t === 1
      ) {
        arr.push(data[i])
      }
      // 股票代码
      if (
        data[i].c.toLowerCase().includes(value.toLowerCase()) &&
        data[i].t === 1
      ) {
        arr.push(data[i])
      }
    }
  }
  // return arrUS.concat(similarArr).concat(arr)
  if (value === '700') {
    arr.unshift(arr.filter((v: any) => v.id === '00700.HK')[0])
  }
  if (process.env.VUE_APP_H5_A_SWITCH === 'on') {
    // 开启A股
    return arrayNonRepeatfy(arrUS.concat(similarArr).concat(arr))
  } else if (process.env.VUE_APP_H5_A_SWITCH === 'off') {
    // 关闭A股
    let arrFiA = arrayNonRepeatfy(arrUS.concat(similarArr).concat(arr))
    arrFiA = arrFiA.filter((v: any) => v.mkt === 'US' || v.mkt === 'HK')
    return arrFiA
  }
}
