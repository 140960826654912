import storage, { StorageType } from '@/utils/storage'
import { useMutation } from '@vue/apollo-composable'
import dayjs from 'dayjs'
import * as gql from '@/graphql'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import { LoginType } from '../model/loginModel'

export const isExpire = (): void => {
  const preDate = storage.rcGetItem(StorageType.local, 'expireTime')
  let diff = 0
  if (preDate) {
    diff = dayjs().diff(JSON.parse(preDate), 'day')
  }
  console.log(diff, '天数', preDate)
  if (diff > 30) {
    // 超过30天删除deviceID
    storage.rcRemoveItem(StorageType.local, 'expireTime')
    storage.rcRemoveItem(StorageType.local, 'deviceId')
  }
}

export const useRegisterDevice = () => {
  const { mutate, onDone } = useMutation(gql.registerDevice, {
    clientId: 'dmz'
  })
  onDone(res => {
    const { registerDevice } = res.data
    if (registerDevice.ok) {
      storage.rcSetItem(
        StorageType.local,
        'deviceId',
        registerDevice.data.deviceId
      )
    }
  })
  return {
    registerDevice: mutate
  }
}

export const useLogin = (loginForm: LoginType) => {
  const router = useRouter()
  const promptDialog = ref(false)
  // 2fa
  const confirmPopupFlag = ref(false)
  const phoneMessage = ref({})
  const { mutate, onDone } = useMutation(gql.lpoaLoginManager, {
    clientId: 'dmz'
  })
  onDone(res => {
    const { lpoaLoginManager } = res?.data
    if (lpoaLoginManager.ok) {
      ElMessage({
        message: '登录成功',
        type: 'success',
        customClass: 'custom-success'
      })
      router.push({
        path: '/lpoa/realTimeData'
      })
      storage.rcSetItem(
        StorageType.local,
        'sessionId',
        lpoaLoginManager.data.sessionId
      )
      storage.rcSetItem(
        StorageType.local,
        'userInfo',
        lpoaLoginManager.data.manager
      )
      // 延迟行情 extendedQuotation
      storage.rcSetItem(
        StorageType.local,
        'extendedQuotation',
        lpoaLoginManager.data.isDelay
      )
    } else {
      if (lpoaLoginManager?.reason?.err === 33142) {
        confirmPopupFlag.value = true
        const obj = JSON.parse(lpoaLoginManager?.reason?.desc)
        phoneMessage.value = {
          username: loginForm.username,
          password: loginForm.password,
          ...obj
        }
      } else if (lpoaLoginManager?.reason?.err === 40008) {
        // 修改密码
        promptDialog.value = true
      } else {
        ElMessage({
          message: lpoaLoginManager?.reason?.desc,
          type: 'error',
          customClass: 'custom-error'
        })
      }
    }
  })
  return {
    loginDirect: mutate,
    promptDialog,
    confirmPopupFlag,
    phoneMessage
  }
}
